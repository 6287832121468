import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import store from 'store';
import { AppState, dataActions } from 'reducers';
import { Product, Unit } from 'lib/types';
import { useMediaQuery } from 'react-responsive';
import { getCurrencyFormat, LG } from 'lib/util';
import './ProductSelection.scss';
import { useSelector } from 'react-redux';
import { getDefaultUnitOfProduct, getUnitShort, mapLocalCartToCartContents } from 'lib/utilityMetods';
import NumberInput from 'components/NumberInput';
import ButtonComponent from 'components/ButtonComponent';
import useCallbackState from 'lib/hooks/useCallbackState';
import { useNotification } from 'components/NotificationComponent/NotificationComponent';
import { addProductToCart } from 'lib/gtm-utils';
import useCartMessage from 'lib/hooks/useCartMessage';

interface ProductSelectionProps {
    selectedProduct: Product;
    inputRef?: React.RefObject<HTMLInputElement>;
}

const ProductSelection = ({ selectedProduct, inputRef }: ProductSelectionProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const { setShowCartMessage } = useCartMessage();

    const edit = useSelector((state: AppState) => state.data.orders.edit);
    const localEditCart = useSelector((state: AppState) => state.data.orders.localEditCart);
    const showNotification = useNotification();

    const productDefaultDimension = Number(selectedProduct.dimensionDefault ?? 1);
    const [productDimension, setProductDimension] = useState(productDefaultDimension);
    const [selectedAdditions, setSelectedAdditions] = useState(Array<boolean>());
    const [productQuantity, setProductQuantity] = useCallbackState<number>(1);

    const [correctAdditions, setCorrectAdditions] = useState(true);
    const [correctQuantity, setCorrectQuantity] = useState(true);
    const [resetNumberInputAdditions, setResetNumberInputAdditions] = useState(false);
    const [resetNumberInputQuantity, setResetNumberInputQuantity] = useState(false);

    const [selectedUnit, setSelectedUnit] = useState<Unit>({ unit: 'szt', isDefault: false, converter: 1, description: 'sztuki' });

    const makeGroupName = () => _.join(selectedProduct?.path, ' ');

    const totalQuantity = +(productQuantity * (selectedProduct.isDimension ? productDimension : 1)).toFixed(2);
    const multiplyByTotalQuantity = (value: number): number => +(totalQuantity * value).toFixed(2);
    const productPriceData = selectedProduct ? (() => {
        const { discount, price, vatRate } = selectedProduct.priceInfo;
        const additionsPrice = _.reduce(
            selectedAdditions,
            (sum, isSelected, index) => (
                isSelected ? sum + selectedProduct.additions[index].price : sum
            ),
            0
        );
        const priceAfterDiscount = (price + additionsPrice) * (1 - discount / 100);
        const vat = vatRate / 100;
        const gross = priceAfterDiscount * (1 + vat);

        return {
            price: multiplyByTotalQuantity(price),
            discount,
            priceAfterDiscount: multiplyByTotalQuantity(priceAfterDiscount),
            priceAfterDiscountPerPiece: priceAfterDiscount * (selectedProduct.isDimension ? productDimension : 1),
            vat: multiplyByTotalQuantity(vat * priceAfterDiscount),
            gross: multiplyByTotalQuantity(gross)
        };
    })() : null;

    const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const unit = selectedProduct.priceInfo.units[parseInt(e.currentTarget.value)];

        setSelectedUnit(unit);
    };

    const addProductSubmit = () => {
        setShowCartMessage();
        if (productQuantity > 0 && (productDimension ?? 1) > 0 && selectedUnit) {
            const product = {
                product: selectedProduct as Product,
                dimension: productDimension,
                quantity: productQuantity as number,
                additionsSelected: selectedAdditions,
                mainProductSymkar: null,
                unit: selectedUnit
            };
            store.dispatch(dataActions[edit ? 'addToLocalEditCart' : 'addToLocalCart']({
                groupPath: makeGroupName() as string,
                product,
                productsType: 'mainProducts'
            }));
            addProductToCart(product);
            setProductQuantity(1);
            setProductDimension(productDefaultDimension);
            setResetNumberInputAdditions(true);
            setResetNumberInputQuantity(true);
        }
    };

    const getQuantityInDefaultUnit = () => productQuantity * selectedUnit.converter;

    useEffect(() => {
        setProductDimension(productDefaultDimension);
        setProductQuantity(1, () => inputRef?.current?.select());
        setSelectedAdditions(_.map(selectedProduct?.additions, (addition) => addition.default ?? false));
        setSelectedUnit(getDefaultUnitOfProduct(selectedProduct));
    }, [selectedProduct, productDefaultDimension]);

    useEffect(() => {
        store.dispatch(dataActions.setEditCart(mapLocalCartToCartContents(localEditCart)));
    }, [localEditCart]);

    if (!productPriceData) return null;

    const PriceDisplay = () => (
        <div className='ProductSelection-addProduct-priceDisplay'>
            <div className='ProductSelection-addProduct-priceDisplay-rowTitle'>
                {getDefaultUnitOfProduct(selectedProduct).unit === 'szt' ? 'Ilość sztuk:' : 'Ilość w metrach bieżących:'}
            </div>
            <div className='ProductSelection-addProduct-priceDisplay-price'>
                {getQuantityInDefaultUnit()}
            </div>
            {(selectedProduct.converterM2 > 0) ?
                <>
                    <div className='ProductSelection-addProduct-priceDisplay-rowTitle'>
                        Ilość w metrach całkowitych (m<sup>2</sup>):
                    </div>
                    <div className='ProductSelection-addProduct-priceDisplay-price'>{(totalQuantity * selectedProduct.converterM2).toFixed(4)}</div>
                </> : null}
            <div className='ProductSelection-addProduct-priceDisplay-rowTitle'>
                Cena netto:
            </div>
            <div className='ProductSelection-addProduct-priceDisplay-price'>
                {getCurrencyFormat(productPriceData.priceAfterDiscount * selectedUnit.converter)}
            </div>
            <div className='ProductSelection-addProduct-priceDisplay-rowTitle' style={{ fontWeight: 'bold' }}>
                CENA:
            </div>
            <div className='ProductSelection-addProduct-priceDisplay-price' style={{ fontWeight: 'bold' }}>
                {getCurrencyFormat(productPriceData.gross * selectedUnit.converter)}
            </div>
        </div>
    );

    const PiecesToFullPackage = () => {
        const packageUnits: Unit[] = [];
        selectedProduct.priceInfo.units.forEach((u) => {
            if (u.description === 'Opakowanie') {
                packageUnits.push(u);
            }
        });

        if (packageUnits.length === 0 || selectedUnit.unit !== 'szt') return <div style={{ visibility: 'hidden' }}> <br /> </div>;

        const numberToFullPackage = packageUnits.reduce((acc, u) => {
            if (productQuantity % u.converter === 0) return 0;

            const newV = u.converter - (productQuantity % u.converter);
            if (acc === -1 || newV < acc) {
                return newV;
            }
            return acc;
        }, -1);

        return (numberToFullPackage === 0) ? <div style={{ visibility: 'hidden' }}> <br /> </div> : <div>do pełnego opakowania: <span style={{ color: 'red' }}>-{numberToFullPackage}</span></div>;
    };

    const UnitsDsiplay = () => (
        <>
            <div className='NewOrder-typeElem'>
                {selectedProduct.priceInfo.units.map((unit, index) => (
                    <Form.Group controlId={unit.unit} className='ProductSelection-addProduct-checkBox' key={unit.unit}>
                        <Form.Check
                            type='radio'
                            name={unit.unit}
                            value={index}
                            checked={unit.unit === selectedUnit.unit}
                            onChange={handleUnitChange}
                        />
                        <Form.Label style={{ margin: 0, padding: '0 0 0 10px' }}>{unit.description}</Form.Label>
                    </Form.Group>))}
            </div>
            <hr />
        </>
    );

    const AdditionsDisplay = () => {
        if (selectedProduct.additions.length === 0) return null;

        return (
            <>
                <div className='ProductSelection-addProduct-desktop-header'>
                    <span className='ProductSelection-addProduct-desktop-title'>OPCJE DODATKOWE</span>
                </div>
                <div className='NewOrder-typeElem'>
                    {selectedProduct.additions.map((addition, index) => (
                        <Form.Group controlId={addition.name} className='ProductSelection-addProduct-checkBox' key={addition.name}>
                            <Form.Check
                                name={addition.name}
                                type='checkbox'
                                defaultChecked={addition.default}
                                disabled={addition.default}
                                checked={selectedAdditions[index]}
                                onChange={() => {
                                    const newArr = [...selectedAdditions];
                                    newArr[index] = !selectedAdditions[index];
                                    setSelectedAdditions(newArr);
                                }}
                            />
                            <Form.Label>{addition.name}</Form.Label>
                        </Form.Group>
                    ))}
                </div>
                <hr />
            </>);
    };

    return isDesktop ? (
        <div className='AssortmentSelection-addProduct-container'>
            <div className='ProductSelection-addProduct ProductSelection-addProduct-desktop'>
                <div className='ProductSelection-addProduct-desktop-header'>
                    <span className='ProductSelection-addProduct-desktop-title'>DOPASUJ PRODUKT</span>
                </div>
                <UnitsDsiplay />
                <div className='NewOrder-typeElem' onKeyPress={({ key }) => key === 'Enter' && addProductSubmit()}>
                    <Form.Group controlId='quantity' style={{ width: '33%', margin: '0px' }}>
                        <span>Ilość:</span>
                        <NumberInput
                            value={productQuantity}
                            setValue={(value) => setProductQuantity(value)}
                            minDesktopResolution={LG}
                            disabledEdit={productQuantity === 1}
                            inputRef={inputRef}
                            correct={correctQuantity}
                            setCorrect={setCorrectQuantity}
                            defaultValue={1}
                            reset={resetNumberInputAdditions}
                            setReset={setResetNumberInputAdditions}
                        />
                        <PiecesToFullPackage />
                    </Form.Group>
                    <Form.Group controlId='dimension' style={{ width: '33%', margin: '0px' }}>
                        <span>Wymiar: </span>
                        <NumberInput
                            value={productDimension}
                            setValue={(value) => setProductDimension(value)}
                            step={0.1}
                            min={selectedProduct.dimensionMin ?? 0.01}
                            max={selectedProduct.dimensionMax ?? 10 ** 5}
                            fractDigits={3}
                            disabled={!selectedProduct.dimensionCanEdit}
                            minDesktopResolution={LG}
                            disabledEdit={productDimension === 0.01}
                            correct={correctAdditions}
                            setCorrect={setCorrectAdditions}
                            defaultValue={productDefaultDimension}
                            reset={resetNumberInputAdditions}
                            setReset={setResetNumberInputAdditions}
                            showMinMaxOnInvalidRange
                        />
                    </Form.Group>
                    <Form.Group style={{ width: '33%', margin: '0px' }}>
                        <span style={{ fontWeight: 'bold' }}>Cena: </span>
                        <div style={{ fontSize: '2rem', fontWeight: 'bold', color: 'darkblue' }}>
                            {getCurrencyFormat(productPriceData.priceAfterDiscountPerPiece)} /{getUnitShort(getDefaultUnitOfProduct(selectedProduct))}
                        </div>
                    </Form.Group>
                </div>
                <hr />
                <AdditionsDisplay />
                <PriceDisplay />
                <div className='ProductSelection-addToCart-mobile' style={{ justifyContent: 'flex-end' }}>
                    <ButtonComponent
                        text='DODAJ DO KOSZYKA'
                        onClick={addProductSubmit}
                        marginTop='0px'
                        disabled={!(correctQuantity && correctAdditions)}
                        onHoverAnimation={3}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div>
            <div className='ProductSelection-addProduct'>
                <div className='ProductSelection-addProductHeader'>
                    <b>{selectedProduct.description}</b>
                </div>
                <UnitsDsiplay />
                <div className='NewOrder-typeElem'>
                    <Form.Group controlId='quantity' style={{ margin: '0 3%' }}>
                        <span>Ilość sztuk: </span>
                        <NumberInput
                            value={productQuantity}
                            setValue={(value) => setProductQuantity(value)}
                            minDesktopResolution={LG}
                            disabledEdit={productQuantity === 1}
                            correct={correctQuantity}
                            setCorrect={setCorrectQuantity}
                            defaultValue={1}
                            reset={resetNumberInputQuantity}
                            setReset={setResetNumberInputQuantity}
                        />
                        <PiecesToFullPackage />
                    </Form.Group>
                    <Form.Group controlId='dimension' style={{ margin: '0 3%', marginBottom: '5px' }}>
                        <span>Wymiar: </span>
                        <NumberInput
                            value={productDimension}
                            setValue={(value) => setProductDimension(value)}
                            step={0.1}
                            min={selectedProduct.dimensionMin ?? 0.01}
                            max={selectedProduct.dimensionMax ?? 10 ** 5}
                            fractDigits={3}
                            disabled={!selectedProduct.dimensionCanEdit}
                            minDesktopResolution={LG}
                            disabledEdit={productDimension === 0.01}
                            correct={correctAdditions}
                            setCorrect={setCorrectAdditions}
                            defaultValue={productDefaultDimension}
                            reset={resetNumberInputAdditions}
                            setReset={setResetNumberInputAdditions}
                        />
                    </Form.Group>
                    <Form.Group style={{ margin: '15px 3% 0', marginBottom: '5px' }}>
                        <span style={{ fontWeight: 'bold' }}>Cena: </span>
                        <div style={{ fontSize: '2rem', fontWeight: 'bold', color: 'darkblue' }}>
                            {getCurrencyFormat(selectedProduct.priceInfo.price * selectedUnit.converter)} /{getUnitShort(selectedUnit)}
                        </div>
                    </Form.Group>
                </div>
                <hr />
                <AdditionsDisplay />
                <PriceDisplay />
            </div>
            <div className='ProductSelection-addToCart-mobile'>
                <ButtonComponent
                    text='DODAJ DO KOSZYKA'
                    marginBottom='0px'
                    onClick={() => {
                        if (productQuantity > 0 && (productDimension ?? 1) > 0 && selectedUnit) {
                            const product = {
                                product: selectedProduct as Product,
                                dimension: productDimension,
                                quantity: productQuantity as number,
                                additionsSelected: selectedAdditions,
                                mainProductSymkar: null,
                                unit: selectedUnit
                            };
                            store.dispatch(dataActions[edit ? 'addToLocalEditCart' : 'addToLocalCart']({
                                groupPath: makeGroupName() as string,
                                product,
                                productsType: 'mainProducts'
                            }));
                            addProductToCart(product);
                        }
                        showNotification('Dodano produkt do koszyka');
                    }}
                    disabled={!(correctQuantity && correctAdditions)}
                    onHoverAnimation={3}
                />
            </div>
        </div>
    );
};

export default ProductSelection;
