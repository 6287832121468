import _ from 'lodash';
import store from 'store';
import { LocalCart, ProductInCart, ProductInLocalCart, CartContent, MainProductsInCart, ProductsInCart, IsodProduct, CartTableItem, ProductInCartTable, CartTableContent, GutteringSystem, GutteringSystemDataV2, GutteringSystemDataV1, Product, Unit } from './types';

const localReducer = (accumulator: number, currentValue: number): number => +accumulator + +currentValue;

export const getTotalQuantityJM = (productInLocalCart: ProductInLocalCart): number => +(productInLocalCart.quantity * (productInLocalCart.product.isDimension ? (productInLocalCart.dimension ?? 1) : 1));

export const getTotalQuantity = (productInLocalCart: ProductInLocalCart): number => +(getTotalQuantityJM(productInLocalCart) * (productInLocalCart.unit?.converter ?? 1)).toFixed(2);

export const getTotalQuantityM2 = (productInLocalCart: ProductInLocalCart): number => +(getTotalQuantity(productInLocalCart) * productInLocalCart.product.converterM2);

export const getPriceBeforeDiscount = (productInLocalCart: ProductInLocalCart): number => {
    const additionsPrice = _.reduce(productInLocalCart.additionsSelected,
        (sum, isSelected, index) => (
            isSelected ? sum + productInLocalCart.product.additions[index].price : sum
        ), 0);
    return productInLocalCart.product.priceInfo.price + additionsPrice;
};

export const getSummaryNetto = (productInLocalCart: ProductInLocalCart): number => {
    const additionsPrice = _.reduce(productInLocalCart.additionsSelected,
        (sum, isSelected, index) => (
            isSelected ? sum + productInLocalCart.product.additions[index].price : sum
        ),
        0);
    const value = +((productInLocalCart.product.priceInfo.price + additionsPrice) * (1 - productInLocalCart.product.priceInfo.discount / 100)).toFixed(2) * getTotalQuantity(productInLocalCart);
    return +(value).toFixed(2);
};

export const getOneProductNetto = (productInLocalCart: ProductInLocalCart): number => {
    const additionsPrice = _.reduce(productInLocalCart.additionsSelected,
        (sum, isSelected, index) => (
            isSelected ? sum + productInLocalCart.product.additions[index].price : sum
        ),
        0);
    const value = (productInLocalCart.product.priceInfo.price + additionsPrice) * (1 - productInLocalCart.product.priceInfo.discount / 100);
    return +(value).toFixed(2);
};

export const getSummaryVat = (productInLocalCart: ProductInLocalCart): number => {
    const vat = productInLocalCart.product.priceInfo.vatRate / 100;
    const value = vat * getSummaryNetto(productInLocalCart);
    return +(value).toFixed(2);
};

export const getTotalPrice = (productInLocalCart: ProductInLocalCart): number => {
    const price = getSummaryNetto(productInLocalCart) + getSummaryVat(productInLocalCart);
    return +(price).toFixed(2);
};

export const getSummaryDiscount = (productInLocalCart: ProductInLocalCart): number => {
    const additionsPrice = _.reduce(productInLocalCart.additionsSelected,
        (sum, isSelected, index) => (
            isSelected ? sum + productInLocalCart.product.additions[index].price : sum
        ),
        0);
    const value = +((productInLocalCart.product.priceInfo.price + additionsPrice) * getTotalQuantity(productInLocalCart)).toFixed(2) - getSummaryNetto(productInLocalCart);
    return +(value).toFixed(2);
};

export const mapMainProductsToProduts = (mainProducts: ProductInLocalCart[]): ProductInCart[] => {
    const products = mainProducts.map((productInLocalCart) => ({
        quantityJm: getTotalQuantityJM(productInLocalCart),
        quantityM2: getTotalQuantityM2(productInLocalCart),
        price: getTotalPrice(productInLocalCart),
        quantity: productInLocalCart.quantity,
        dimension: productInLocalCart.dimension,
        additionsSelected: productInLocalCart.additionsSelected,
        product: productInLocalCart.product,
        mainProductSymkar: productInLocalCart.mainProductSymkar,
        unit: productInLocalCart.unit
    }));
    return products;
};

interface LocalCartProductGroup {
    groupPath: string;
    mainProducts: ProductInLocalCart[];
    additionalProducts: ProductInLocalCart[];
}

const mapToCartGroups = (
    productGroups: (LocalCartProductGroup | IsodProduct[])[],
    getGroupName: (_: LocalCartProductGroup | IsodProduct[]) => string,
    getMainProducts: (_: LocalCartProductGroup | IsodProduct[]) => (IsodProduct | ProductInLocalCart)[],
    getAdditionalProducts: (_: LocalCartProductGroup | IsodProduct[]) => (IsodProduct | ProductInLocalCart)[]
) => productGroups.map((group) => {
    const mainProducts = getMainProducts(group);
    const additionalProducts = getAdditionalProducts(group);
    const quantity = +(mainProducts.map((product) => getTotalQuantity(product as ProductInCart)).reduce(localReducer, 0)).toFixed(2);
    const quantityM2 = +(mainProducts.map((product) => getTotalQuantityM2(product as ProductInCart)).reduce(localReducer, 0)).toFixed(2);

    return ({
        groupName: getGroupName(group),
        mainProducts: {
            products: mapMainProductsToProduts(mainProducts as ProductInLocalCart[]),
            quantityJm: quantity,
            quantity: mainProducts.map((product) => product.quantity).reduce(localReducer, 0),
            quantityM2,
            price: +(mainProducts.map((product) => getTotalPrice(product as ProductInLocalCart)).reduce(localReducer, 0)).toFixed(2)
        },
        additionalProducts: {
            products: mapMainProductsToProduts(additionalProducts as ProductInLocalCart[]),
            quantity: additionalProducts.map((product) => product.quantity).reduce(localReducer, 0),
            price: +(additionalProducts.map((product) => getTotalPrice(product as ProductInLocalCart)).reduce(localReducer, 0)).toFixed(2)
        }
    });
});

export const getDefaultUnitOfProduct = (product: Product): Unit => {
    let unit = product.priceInfo.units.find((u) => u.isDefault);
    if (unit === undefined) {
        unit = {
            unit: product.unit,
            isDefault: true,
            converter: 1,
            description: product.unit
        };
    }

    return unit;
};

export const mapLocalCartToCartContents = (localCart: LocalCart): CartContent => {
    const groups = mapToCartGroups(
        localCart.productGroups as LocalCartProductGroup[],
        (group) => (group as LocalCartProductGroup).groupPath,
        (group) => (group as LocalCartProductGroup).mainProducts,
        (group) => (group as LocalCartProductGroup).additionalProducts
    );

    groups.push(...mapToCartGroups(
        localCart.isod,
        (group) => `ISOD ${(group as IsodProduct[])[0].product.path.join(' ')}`,
        (group) => (group as IsodProduct[]).filter((product) => product.product.symKar === (group as IsodProduct[])[0].product.symKar),
        (group) => (group as IsodProduct[]).filter((product) => product.product.symKar !== (group as IsodProduct[])[0].product.symKar)
    ));

    const gutterGroups: { groupName: string, products: ProductsInCart }[] = [];
    localCart.gutterings.forEach((guttering) => {
        const groupName = guttering.product.path.join(' ');
        const foundGroup = gutterGroups.find((g) => g.groupName === groupName);
        const { product } = guttering;
        const gutteringPrice = +(+(+(+(product.priceInfo.price * (1 - product.priceInfo.discount / 100)).toFixed(2) * guttering.quantity).toFixed(2) * (1 + product.priceInfo.vatRate / 100)).toFixed(2));
        const productInCart = {
            ...guttering,
            quantityJm: 0,
            quantityM2: 0,
            dimension: null,
            price: gutteringPrice,
            additionsSelected: null,
            mainProductSymkar: null,
            unit: getDefaultUnitOfProduct(guttering.product)
        };
        if (foundGroup) {
            foundGroup.products.price += gutteringPrice;
            foundGroup.products.quantity += guttering.quantity;
            foundGroup.products.products.push(productInCart);
        } else {
            gutterGroups.push({
                groupName,
                products: {
                    price: gutteringPrice,
                    quantity: guttering.quantity,
                    products: [productInCart]
                }
            });
        }
    });

    const mapGroups = (func: (_: MainProductsInCart | ProductsInCart) => number) => {
        const value = (groups || gutterGroups) ? (
            groups.map((group) => group.mainProducts)
                .map(func)
                .reduce(localReducer, 0) +
            groups.map((group) => group.additionalProducts)
                .map(func)
                .reduce(localReducer, 0) +
            gutterGroups.map((g) => g.products)
                .map(func)
                .reduce(localReducer, 0)
        ) : 0;
        return +(value).toFixed(2);
    };

    const cartContent = {
        productGroups: groups,
        gutterGroups,
        summaryPrice: mapGroups((product) => product.price),
        itemsCount: mapGroups((mainProducts) => mainProducts.products.length),
        summaryPriceBeforeDiscount: mapGroups((mainProducts) => mainProducts.products
            .map((product) => +(getPriceBeforeDiscount(product) * getTotalQuantity(product)).toFixed(2))
            .reduce(localReducer, 0)),
        summaryDiscont: mapGroups((mainProducts) => mainProducts.products.map(getSummaryDiscount).reduce(localReducer, 0)),
        summaryVat: mapGroups((mainProducts) => mainProducts.products.map(getSummaryVat).reduce(localReducer, 0))
    };

    return cartContent;
};

export const mapProductInLocalCartToProductInCart = (prd: ProductInLocalCart): ProductInCart => ({
    product: prd.product,
    quantity: prd.quantity,
    dimension: prd.dimension,
    additionsSelected: prd.additionsSelected,
    quantityJm: getTotalQuantityJM(prd),
    quantityM2: getTotalQuantityM2(prd),
    price: getTotalPrice(prd),
    mainProductSymkar: prd.mainProductSymkar,
    unit: prd.unit
} as ProductInCart);

const createCartTableItem = () => ({
    title: '',
    identifier: '',
    products: [] as ProductInCartTable[],
    totalPrice: 0,
    totalNetto: 0,
    totalVat: 0,
    totalQuantity: 0,
    totalQuantityJM: 0,
    totalQuantityM2: 0,
    additionalItemsSummary: {
        quantity: 0,
        netto: 0,
        vat: 0,
        gross: 0
    }
} as CartTableItem);

export const mapLocalCartToCartTableContent = (localCart: LocalCart) => {
    const newContent: CartTableContent = {
        mainItems: {} as { [key: string]: CartTableItem },
        additionalItems: {} as { [key: string]: { [key: string]: CartTableItem } },
        isodItems: {} as { [key: string]: CartTableItem },
        gutteringsItems: {} as { [key: string]: CartTableItem }
    };

    _.map(localCart.productGroups, ({ groupPath, mainProducts, additionalProducts }) => {
        _.map(mainProducts, (prd, prdIndex) => {
            const productInCartTable: ProductInCartTable = {
                ...mapProductInLocalCartToProductInCart(prd),
                groupPath,
                indexInGroup: prdIndex,
                isGutter: false,
                isIsod: false
            };

            if (!newContent.mainItems[prd.product.symKar]) {
                newContent.mainItems[prd.product.symKar] = createCartTableItem();
                newContent.mainItems[prd.product.symKar].title = prd.product.description;
                newContent.mainItems[prd.product.symKar].identifier = prd.product.symKar;
            }
            newContent.mainItems[prd.product.symKar].products.push(productInCartTable);
            newContent.mainItems[prd.product.symKar].totalPrice += productInCartTable.price;
            newContent.mainItems[prd.product.symKar].totalNetto += getSummaryNetto(prd);
            newContent.mainItems[prd.product.symKar].totalVat += getSummaryVat(prd);
            newContent.mainItems[prd.product.symKar].totalQuantity += productInCartTable.quantity;
            newContent.mainItems[prd.product.symKar].totalQuantityJM += productInCartTable.quantityJm;
            newContent.mainItems[prd.product.symKar].totalQuantityM2 += productInCartTable.quantityM2;
        });

        _.map(additionalProducts, (prd, prdIndex) => {
            const productInCartTable: ProductInCartTable = {
                ...mapProductInLocalCartToProductInCart(prd),
                groupPath,
                indexInGroup: prdIndex,
                isGutter: false,
                isIsod: false
            };

            if (!newContent.mainItems[prd.mainProductSymkar as string]) {
                let mainProductTitle: string = '';
                if (!prd.mainProduct) {
                    const storeProducts = store.getState().data.orders.groupsAndProducts.products;
                    const mainProductInfo = _.find(storeProducts, (sp) => sp.symKar === prd.mainProductSymkar);
                    if (mainProductInfo) mainProductTitle = mainProductInfo.description;
                } else {
                    mainProductTitle = prd.mainProduct.description;
                }
                newContent.mainItems[prd.mainProductSymkar as string] = createCartTableItem();
                newContent.mainItems[prd.mainProductSymkar as string].title = mainProductTitle;
                newContent.mainItems[prd.mainProductSymkar as string].identifier = prd.mainProductSymkar as string;
            }
            newContent.mainItems[prd.mainProductSymkar as string].additionalItemsSummary.quantity += productInCartTable.quantity;
            newContent.mainItems[prd.mainProductSymkar as string].additionalItemsSummary.netto += getSummaryNetto(productInCartTable);
            newContent.mainItems[prd.mainProductSymkar as string].additionalItemsSummary.vat += getSummaryVat(productInCartTable);
            newContent.mainItems[prd.mainProductSymkar as string].additionalItemsSummary.gross += productInCartTable.price;
            if (!newContent.additionalItems[prd.mainProductSymkar as string]) {
                newContent.additionalItems[prd.mainProductSymkar as string] = {} as { [key: string]: CartTableItem };
            }
            if (!newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar]) {
                newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar] = createCartTableItem();
                newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].title = prd.product.description;
                newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].identifier = `${prd.mainProductSymkar}${prd.product.symKar}`;
            }
            newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].products.push(productInCartTable);
            newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].totalPrice += productInCartTable.price;
            newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].totalNetto += getSummaryNetto(prd);
            newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].totalVat += getSummaryVat(prd);
            newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].totalQuantity += productInCartTable.quantity;
            newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].totalQuantityJM += productInCartTable.quantityJm;
            newContent.additionalItems[prd.mainProductSymkar as string][prd.product.symKar].totalQuantityM2 += productInCartTable.quantityM2;
        });
    });

    _.map(localCart.isod, (grp) => {
        _.map(grp, (prd, prdIndex) => {
            const productInCartTable: ProductInCartTable = {
                ...mapProductInLocalCartToProductInCart(prd as ProductInLocalCart),
                groupPath: `ISOD ${prd.product.path.join(' ')}`,
                indexInGroup: prdIndex,
                isGutter: false,
                isIsod: true
            };

            if (!newContent.isodItems[prd.product.symKar]) {
                newContent.isodItems[prd.product.symKar] = createCartTableItem();
                newContent.isodItems[prd.product.symKar].title = prd.product.description;
                newContent.isodItems[prd.product.symKar].identifier = `ISOD${prd.product.symKar}`;
            }
            newContent.isodItems[prd.product.symKar].products.push(productInCartTable);
            newContent.isodItems[prd.product.symKar].totalPrice += productInCartTable.price;
            newContent.isodItems[prd.product.symKar].totalNetto += getSummaryNetto(prd as ProductInLocalCart);
            newContent.isodItems[prd.product.symKar].totalVat += getSummaryVat(prd as ProductInLocalCart);
            newContent.isodItems[prd.product.symKar].totalQuantity += productInCartTable.quantity;
            newContent.isodItems[prd.product.symKar].totalQuantityJM += productInCartTable.quantityJm;
            newContent.isodItems[prd.product.symKar].totalQuantityM2 += productInCartTable.quantityM2;
        });
    });

    _.map(localCart.gutterings, (prd, prdIndex) => {
        const productInCartTable: ProductInCartTable = {
            ...mapProductInLocalCartToProductInCart(prd as ProductInLocalCart),
            systemData: store.getState().conf.GutterCreatorVersion === '2' ? prd.systemData as GutteringSystemDataV2 : prd.systemData as GutteringSystemDataV1,
            groupPath: prd.product.path.join(' '),
            indexInGroup: prdIndex,
            isGutter: true,
            isIsod: false
        };

        let identifier: string = '';
        let title: string = '';
        if (store.getState().conf.GutterCreatorVersion === '2') {
            const systemData: GutteringSystemDataV2 = (prd.systemData as GutteringSystemDataV2);
            identifier = JSON.stringify(systemData);
            title = `${systemData.gutterShape} ${systemData.grille} ${systemData.tubeShape} ${systemData.material} ${systemData.size} ${systemData.colorName}`;
        } else {
            const gutteringSystems: GutteringSystem[] = store.getState().data.orders.gutteringSystems as GutteringSystem[];
            const systemNInfo = _.find(gutteringSystems, (sys) => sys.name === (productInCartTable.systemData as GutteringSystemDataV1).systemN);
            const systemInfo = _.find(systemNInfo?.systems, (sys) => sys.name === (productInCartTable.systemData as GutteringSystemDataV1).system);
            const colorName = systemInfo?.colors[(productInCartTable.systemData as GutteringSystemDataV1).color || 0].name;
            const sizeName = systemInfo?.sizes[(productInCartTable.systemData as GutteringSystemDataV1).size || 0].name;
            identifier = `${systemNInfo?.name} ${systemInfo?.name} ${sizeName} ${colorName}`;
        }
        if (!newContent.gutteringsItems[identifier]) {
            newContent.gutteringsItems[identifier] = createCartTableItem();
            newContent.gutteringsItems[identifier].title = title;
            newContent.gutteringsItems[identifier].identifier = identifier;
        }
        newContent.gutteringsItems[identifier].products.push(productInCartTable);
        newContent.gutteringsItems[identifier].totalPrice += productInCartTable.price;
        newContent.gutteringsItems[identifier].totalNetto += getSummaryNetto(prd as ProductInLocalCart);
        newContent.gutteringsItems[identifier].totalVat += getSummaryVat(prd as ProductInLocalCart);
        newContent.gutteringsItems[identifier].totalQuantity += productInCartTable.quantity;
        newContent.gutteringsItems[identifier].totalQuantityJM += productInCartTable.quantityJm;
        newContent.gutteringsItems[identifier].totalQuantityM2 += productInCartTable.quantityM2;
    });

    return newContent;
};

export const getUnitOrDefault = (product: ProductInCart | ProductInLocalCart): Unit => product.unit ?? getDefaultUnitOfProduct(product.product);

export const getUnitShort = (unit: Unit): string => {
    const UNITS_SHORTS: {[key: string]: string} = {
        Sztuki: 'szt.',
        Opakowanie: 'opk.',
        'Metr bieżący': 'mb'
    };

    let short = unit.description;
    Object.keys(UNITS_SHORTS).forEach((u, index) => {
        if (unit.description.indexOf(u) !== -1) {
            short = UNITS_SHORTS[u];
        }
    });

    return short;
};
